@import "../../styles/flex-mixins.scss";
@import "../../styles/variables.scss";

$voter-search-form-width: 700px;

.voter-search-form {
  max-width: $voter-search-form-width;
  .form-input {
    @include flex(1);
    min-width: 200px;
  }
  .ant-form-explain {
    list-style: none;
    color: $appyred;
    font-size: 0.8rem;
  }
  .state-select {
    max-width: $voter-search-form-width;
    width: 100%;
  }
}
