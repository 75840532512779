@import "../../styles/flex-mixins.scss";
@import "../../styles/variables.scss";

$new-voter-report-width: 800px;

.new-voter-report-section {
  max-width: $new-voter-report-width;
  width: 100%;
}

.selection-box {
  background: white;
  color: $dark-blue;
  border: 1px solid $dark-blue;
  padding: 0 1rem;
  border-radius: 10px;
  cursor: pointer;

  &.selected {
    background: $dark-blue;
    color: white;
  }
}
