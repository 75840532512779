@font-face {
  font-family: 'BasicSans-BlackItalic';
  src: url('../../../assets/fonts/basic-sans-black-italic/basic-sans-black-italic.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-black-italic/basic-sans-black-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-black-italic/basic-sans-black-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/basic-sans-black-italic/basic-sans-black-italic.woff')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../../../assets/fonts/basic-sans-black-italic/basic-sans-black-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-black-italic/basic-sans-black-italic.svg#basic-sans-black-italic')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-Black';
  src: url('../../../assets/fonts/basic-sans-black/basic-sans-black.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-black/basic-sans-black.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-black/basic-sans-black.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../../../assets/fonts/basic-sans-black/basic-sans-black.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../../assets/fonts/basic-sans-black/basic-sans-black.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-black/basic-sans-black.svg#basic-sans-black')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-BoldItalic';
  src: url('../../../assets/fonts/basic-sans-bold-italic/basic-sans-bold-italic.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-bold-italic/basic-sans-bold-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-bold-italic/basic-sans-bold-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/basic-sans-bold-italic/basic-sans-bold-italic.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('../../../assets/fonts/basic-sans-bold-italic/basic-sans-bold-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-bold-italic/basic-sans-bold-italic.svg#basic-sans-bold-italic')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-Bold';
  src: url('../../../assets/fonts/basic-sans-bold/basic-sans-bold.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-bold/basic-sans-bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-bold/basic-sans-bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../../assets/fonts/basic-sans-bold/basic-sans-bold.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../../assets/fonts/basic-sans-bold/basic-sans-bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-bold/basic-sans-bold.svg#basic-sans-bold') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-ExtralightItalic';
  src: url('../../../assets/fonts/basic-sans-extralight-italic/basic-sans-extralight-italic.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-extralight-italic/basic-sans-extralight-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../../../assets/fonts/basic-sans-extralight-italic/basic-sans-extralight-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/basic-sans-extralight-italic/basic-sans-extralight-italic.woff')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../../../assets/fonts/basic-sans-extralight-italic/basic-sans-extralight-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-extralight-italic/basic-sans-extralight-italic.svg#basic-sans-extralight-italic')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-Extralight';
  src: url('../../../assets/fonts/basic-sans-extralight/basic-sans-extralight.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-extralight/basic-sans-extralight.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-extralight/basic-sans-extralight.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/basic-sans-extralight/basic-sans-extralight.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('../../../assets/fonts/basic-sans-extralight/basic-sans-extralight.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-extralight/basic-sans-extralight.svg#basic-sans-extralight')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-Italic';
  src: url('../../../assets/fonts/basic-sans-italic/basic-sans-italic.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-italic/basic-sans-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-italic/basic-sans-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/basic-sans-italic/basic-sans-italic.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('../../../assets/fonts/basic-sans-italic/basic-sans-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-italic/basic-sans-italic.svg#basic-sans-italic')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-LightItalic';
  src: url('../../../assets/fonts/basic-sans-light-italic/basic-sans-light-italic.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-light-italic/basic-sans-light-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-light-italic/basic-sans-light-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/basic-sans-light-italic/basic-sans-light-italic.woff')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../../../assets/fonts/basic-sans-light-italic/basic-sans-light-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-light-italic/basic-sans-light-italic.svg#basic-sans-light-italic')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-Light';
  src: url('../../../assets/fonts/basic-sans-light/basic-sans-light.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-light/basic-sans-light.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-light/basic-sans-light.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../../../assets/fonts/basic-sans-light/basic-sans-light.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../../assets/fonts/basic-sans-light/basic-sans-light.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-light/basic-sans-light.svg#basic-sans-light')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-SemiboldItalic';
  src: url('../../../assets/fonts/basic-sans-semibold-italic/basic-sans-semibold-italic.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-semibold-italic/basic-sans-semibold-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../../../assets/fonts/basic-sans-semibold-italic/basic-sans-semibold-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/basic-sans-semibold-italic/basic-sans-semibold-italic.woff')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../../../assets/fonts/basic-sans-semibold-italic/basic-sans-semibold-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-semibold-italic/basic-sans-semibold-italic.svg#basic-sans-semibold-italic')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-Semibold';
  src: url('../../../assets/fonts/basic-sans-semibold/basic-sans-semibold.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-semibold/basic-sans-semibold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-semibold/basic-sans-semibold.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/basic-sans-semibold/basic-sans-semibold.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('../../../assets/fonts/basic-sans-semibold/basic-sans-semibold.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-semibold/basic-sans-semibold.svg#basic-sans-semibold')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-ThinItalic';
  src: url('../../../assets/fonts/basic-sans-thin-italic/basic-sans-thin-italic.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-thin-italic/basic-sans-thin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-thin-italic/basic-sans-thin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/basic-sans-thin-italic/basic-sans-thin-italic.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('../../../assets/fonts/basic-sans-thin-italic/basic-sans-thin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-thin-italic/basic-sans-thin-italic.svg#basic-sans-thin-italic')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-Thin';
  src: url('../../../assets/fonts/basic-sans-thin/basic-sans-thin.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans-thin/basic-sans-thin.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans-thin/basic-sans-thin.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../../assets/fonts/basic-sans-thin/basic-sans-thin.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../../assets/fonts/basic-sans-thin/basic-sans-thin.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/basic-sans-thin/basic-sans-thin.svg#basic-sans-thin') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'BasicSans-Regular';
  src: url('../../../assets/fonts/basic-sans/basic-sans.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans/basic-sans.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans/basic-sans.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../../assets/fonts/basic-sans/basic-sans.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../../assets/fonts/basic-sans/basic-sans.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../../../assets/fonts/basic-sans/basic-sans.svg#basic-sans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'BasicSans';
  src: url('../../../assets/fonts/basic-sans/basic-sans.eot'); /* IE9 Compat Modes */
  src: url('../../../assets/fonts/basic-sans/basic-sans.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/basic-sans/basic-sans.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../../assets/fonts/basic-sans/basic-sans.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../../../assets/fonts/basic-sans/basic-sans.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../../../assets/fonts/basic-sans/basic-sans.svg#basic-sans')
      format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/manrope/webfonts/Manrope-Regular.woff2') format('woff2'),
    url('../../../assets/fonts/manrope/otf/Manrope-Regular.otf') format('opentype'),
    url('../../../assets/fonts/manrope/ttf/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

//extra light
@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/manrope/webfonts/Manrope-ExtraLight.woff2') format('woff2'),
    url('../../../assets/fonts/manrope/otf/Manrope-ExtraLight.otf') format('opentype'),
    url('../../../assets/fonts/manrope/ttf/Manrope-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

//light
@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/manrope/webfonts/Manrope-Light.woff2') format('woff2'),
    url('../../../assets/fonts/manrope/otf/Manrope-Light.otf') format('opentype'),
    url('../../../assets/fonts/manrope/ttf/Manrope-Light.ttf') format('truetype');
  font-weight: 300;
}

//medium
@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/manrope/webfonts/Manrope-Medium.woff2') format('woff2'),
    url('../../../assets/fonts/manrope/otf/Manrope-Medium.otf') format('opentype'),
    url('../../../assets/fonts/manrope/ttf/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
}

//semiBold
@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/manrope/webfonts/Manrope-SemiBold.woff2') format('woff2'),
    url('../../../assets/fonts/manrope/otf/Manrope-SemiBold.otf') format('opentype'),
    url('../../../assets/fonts/manrope/ttf/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

//Bold
@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/manrope/webfonts/Manrope-Bold.woff2') format('woff2'),
    url('../../../assets/fonts/manrope/otf/Manrope-Bold.otf') format('opentype'),
    url('../../../assets/fonts/manrope/ttf/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
}

//Bold
@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/manrope/webfonts/Manrope-ExtraBold.woff2') format('woff2'),
    url('../../../assets/fonts/manrope/otf/Manrope-ExtraBold.otf') format('opentype'),
    url('../../../assets/fonts/manrope/ttf/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
