@import "../../styles/variables.scss";

$activity-title-black: #2d343c;
.header-title {
  color: $activity-title-black;
  font-size: 17px;
  letter-spacing: 0.32px;
  line-height: 24px;
}

.header-date {
  color: $activity-title-black;
  font-size: 12px;
  letter-spacing: 0;
  opacity: 0.65;
}
