.ant-steps.site-navigation-steps {
  &.no-icons {
    .ant-steps-icon {
      display: none;
    }
  } 

  .ant-steps-item-active {
    // coming soon.
  }
}