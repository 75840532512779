@import 'variables.scss';

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  // background-color: #faf8f0;
  margin: 0;
}

#root {
  height: 100%;
}

.bg-auth-cyan {
  // background: #c2dee2;
  background: #fafafa;
}

.default-page-max-width {
  max-width: $default-page-max-width;
  width: 100%;

  &.screen-lg {
    max-width: $large-screen;
  }

  &.screen-xl {
    max-width: 1200px;
  }
}

.line-break {
  width: 100%;
  border: $base-border;
  height: 1px;
}

.text-error,
.formik-error {
  color: $appyred;
}

.text-underline {
  text-decoration: underline;
}

.border-bottom {
  border-bottom: 1px solid #e8e8e8;
}

.campaign-icon {
  border-radius: 24px;
  height: 48px;
  width: 48px;
}

.pointer {
  cursor: pointer;
}

// AntD Override

.ant-btn-link {
  color: $link;
}

.ant-btn-primary {
  background: $dark-blue;
  border-color: $dark-blue;
  &:hover,
  &:focus {
    background: $dark-blue;
    border-color: $dark-blue;
  }
}

.ant-menu-item {
  a:hover {
    color: $dark-blue;
  }
}

.ant-switch-checked {
  background: $teal;
}

::selection {
  background: $teal;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: $teal;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: $teal;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: $teal;
  background: #fff;
  border-color: $teal;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $teal !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: $teal;
}

.ant-radio:hover .ant-radio-inner {
  border-color: $teal;
}

.ant-menu-item:hover {
  color: $coral;
}

.ant-spin-dot-item {
  background-color: $coral;
}

.ant-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.wide {
    min-width: 250px;
  }
}

.ant-modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-message-custom-content {
  align-items: center;
  display: flex;
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
