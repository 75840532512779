@import "../../styles/flex-mixins.scss";
@import "../../styles/variables.scss";

$voter-match-width: 650px;

.voter-match-section {
  max-width: $voter-match-width;
  width: 100%;
}

.loading {
  text-align: center;
  margin: 2em;
}

.recent-election-title {
  font-size: 1.1rem;
}

.election-year {
  font-size: 1.1rem;
}

.text-dark-gray {
  color: #2d343c;
}

.text-sans-black-italic {
  font-family: "BasicSans-BlackItalic";
}

.text-sans-reg {
  font-family: "BasicSans-Regular";
}

.text-sans-black {
  font-family: "BasicSans-Black";
}

.text-sans-semi-bold {
  font-family: "BasicSans-SemiBold";
}

.bad-match-link {
  opacity: 0.8;
  color: #4d8ff1;
}
