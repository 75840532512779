@import "../../styles/flex-mixins.scss";
@import "../../styles/variables.scss";

.voter-search-list-item {
  border: 1px solid $light-black !important;
  background: white;
  cursor: pointer;
  &:hover {
    background: $base-border-color;
    color: $light-black;
  }
}
