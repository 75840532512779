@import 'flex-mixins.scss';

.flexbox {
  @include flexbox;
}

.flex-row {
  @include flexbox;
  @include flex-direction(row);
}

.flex-col {
  @include flexbox;
  @include flex-direction(column);
}

.align-items-start {
  @include align-items(flex-start);
}

.align-items-center {
  @include align-items(center);
}

.align-items-end {
  @include align-items(flex-end);
}

.justify-content-start {
  @include justify-content(flex-start);
}

.justify-content-center {
  @include justify-content(center);
}

.justify-content-end {
  @include justify-content(flex-end);
}

.justify-content-between {
  @include justify-content(space-between);
}

.justify-content-around {
  @include justify-content(space-around);
}

.flex-wrap {
  @include flex-wrap(wrap);
}

.flex-wrap-reverse {
  @include flex-wrap(wrap-reverse);
}

.flex1 {
  @include flex(1);
}
