body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h2 {
  font-size: 1.25em;
  margin-bottom: 0;
}

p {
  margin: 0 0 $small-spacing;
  color: #000;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

.font-semibold {
  font-family: "BasicSans-SemiBold";
}

.font-bold {
  font-family: "BasicSans-Bold";
}

.font-light {
  font-family: "BasicSans-Light";
}

.font-regular {
  font-family: "BasicSans-Regular";
}
