// Breakpoints
$mobile: 480px;
$medium-screen: 600px;
$large-screen: 1012px;

$default-page-max-width: 700px;

// Font Override
$font-stack-system: 'BasicSans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Typography
$base-font-family: $font-stack-system;
$heading-font-family: $base-font-family;
$font-semibold: 'BasicSans-SemiBold';
$font-bold: 'BasicSans-Bold';
$font-light: 'BasicSans-Light';

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0;
$base-spacing: $base-line-height * 1em;
$small-spacing: calc($base-spacing / 2);
$base-z-index: 0;

// Colors
$cream: #fefefe;
$appyred: #c10f41;
$appyblue: #233b88;

$light-blue: #7fc6f5;
$navbar-blue: #1266a8;
$blue: #2a3b71;
$sapient-blue: #22273b;
$light-black: #333;
$dark-gray: #71717d;
$medium-gray: #b2b8bf;
$light-gray: #f5f6f7;
$light-red: #f65e78;
//$red: #F65E78;
$red: #eb5056;
//$red: #EA525A;  sapient
$dark-red: #f31d42;
$light-green: #7ed582;
$green: #58cea9;
$dark-green: #186f1c;
$purple: #ee7eeb;

$coral: #f3786c;
$link: #f3786c;
$dark-blue: #23293e;
$teal: #6ccbda;
$message-background: #c2dee2;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $blue;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);
$deep-shadow: 0px 24px 38px 3px rgba(0, 0, 0, 0.08), 0px 9px 46px 8px rgba(0, 0, 0, 0.08),
  0px 11px 15px 0 rgba(0, 0, 0, 0.1);
$shallow-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 3px 20px 0px rgba(0, 0, 0, 0.08),
  0px 2px 4px 0px rgba(0, 0, 0, 0.1);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow,
  0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
